(function() {
    angular.module('EntrakV5').controller('registrationController', registrationController);

    function registrationController($scope, $state, Service, Api, $timeout) {
        console.log('registrationController');

        //$stateParams.landlord
        $scope.emailPlaceHolder = Service.translate("login.email");
        $scope.fNamePlaceHolder = Service.translate('login.fName');
        $scope.lNamePlaceHolder = Service.translate('login.lName');

        $scope.isRegistration = true;
        $scope.profileCreated = false;
        $scope.errMsg = null;
        $scope.regData = {
            fName: '',
            lName: '',
            email: '',
            captchaCode: '',
            loading: false
        }

        $scope.refreshCaptcha = function(){
            // $scope.loadingCaptchaImg = true;
            // Api.getCaptcha().then(function(res){
            //     document.getElementById('captchaContainer').innerHTML = res.data;
            //     $scope.loadingCaptchaImg = false;
            // }, function(res){
            //     console.log("Unable to load captcha");
            // });
        }
        $scope.refreshCaptcha();

        $scope.isEmail = function(email){
            if (email)
                email = email.trim();
            if (email && Service.isEmail(email))
                return true;
            return false;
        }

        $scope.register = function(){
            // var email = $scope.regData.email.trim();
            // var fName = $scope.regData.fName.trim();
            // var lName = $scope.regData.lName.trim();
            // if ($scope.isEmail(email)){
            //     $scope.errMsg = null;
            // } else {
            //     $scope.errMsg = Service.translate("error.invalidEmail");
            //     return;
            // }
            // $scope.regData.loading = true;
            // Api.valiateCaptcha($scope.regData.captchaCode.trim()).then(function(res){
            //     if (res.data.code == 0){
            //         return Api.selfRegister(email, fName, lName);
            //     } else {
            //         throw 'captchaFail';
            //     }
            // }, function(res){
            //     throw 'captchaFail';
            // }).then(function(res){
            //     $scope.regData.loading = false;
            //     $scope.redirectSecond = 15;
            //     $scope.profileCreated = true;
            //     runTimer();
            // }, function(res){
            //     $scope.regData.loading = false;
            //     if (res === 'captchaFail'){
            //         $scope.errMsg = Service.translate("error.captchaFail");
            //     } else if (res.data.message.indexOf("not allow self-registration") > -1){
            //         $scope.errMsg = Service.translate("error.selfRegDisabled");    
            //     } else {
            //         $scope.errMsg = Service.translate("error.generalRegFail");
            //     }
            // });
        }

        function runTimer(){
            if ($scope.profileCreated){
                $timeout(function(){
                    if ($scope.redirectSecond){
                        $scope.redirectSecond--;
                        runTimer();
                    } else {
                        $state.go("login", { landlord: null });
                    }
                }, 1000);
            }
        }

        $scope.$on('$destroy', function() {
            $scope.profileCreated = false;
            console.log("registrationController destroy");
        });
    }
})();
